@tailwind base;
@tailwind components;
@tailwind utilities;


.select-tags .select__multi-value{
    border-radius: 9999px;
}

.nc-SectionBecomeAnAuthor iframe{
    border-radius: 1.5rem;
}

.nc-SectionBecomeAnAuthor > div:nth-child(2){
    @apply !w-[90vw] md:!w-[90vw] lg:!w-[80vw] xl:!w-[60vw] 2xl:!w-[50vw];
    @apply !h-[60vw] md:!h-[50vw] lg:!h-[45vw] xl:!h-[35vw] 2xl:!h-[30vw]; 
}

.nc-SectionBecomeAnAuthor > .footer-area{
    @apply !w-[90vw] md:!w-[90vw] lg:!w-[80vw] xl:!w-[60vw] 2xl:!w-[50vw]; 
}