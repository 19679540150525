#cuboid {
    width: 400px;
    margin: 2rem auto;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    backface-visibility: visible;
    -webkit-backface-visibility: visible;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

#cuboid form {
    transform: translateZ(-20px);
    -webkit-transform: translateZ(-20px);
    -moz-transform: translateZ(-20px);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    height: 40px;
    transition: all 0.35s;
    -webkit-transition: all 0.35s;
}

.cuboid-text {
    line-height: 40px;
    height: 40px;
    background: #333333;
    color: #ffffff;
    padding-left: 10px;
    letter-spacing: 1px;
}

p.cuboid-text {
    text-align: center;
}

.cuboid-text.finish {
    background-color: #359118;
    color: #ffffff;
}

.loader {
    background: #333333;
    animation: phase 1s infinite;
    -webkit-animation: phase 1s infinite;
    -moz-animation: phase 1s infinite;
}

@keyframes phase {
    50% {
        background: #333333;
    }
}
#email {
    background: white;
    outline: none;
    border: 0 none;
    font: inherit;
    text-align: left;
    color: #333333;
    display: block;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #333333;
}

#email:focus {
    outline: none !important;
    border: 1px solid #333333;
    box-shadow: none;
}
#submit {
    display: none;
}

.submit-icon,
.reset-icon {
    position: absolute;
    top: 50%;
    right: 0;
    line-height: 40px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    padding: 0 10px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    cursor: pointer;
}

.submit-icon.active {
    color: hsl(120, 40%, 30%);
}
.reset-icon {
    color: rgba(255, 255, 255, 0.25);
    font-size: 14px;
}

#cuboid div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
#cuboid div:nth-child(1) {
    transform: rotateX(0deg) translateZ(20px);
    -webkit-transform: rotateX(0deg) translateZ(20px);
    -moz-transform: rotateX(0deg) translateZ(20px);
}
#cuboid div:nth-child(2) {
    transform: rotateX(-90deg) translateZ(20px);
    -webkit-transform: rotateX(-90deg) translateZ(20px);
    -moz-transform: rotateX(-90deg) translateZ(20px);
}
#cuboid div:nth-child(3) {
    transform: rotateX(-180deg) translateZ(20px);
    -webkit-transform: rotateX(-180deg) translateZ(20px);
    -moz-transform: rotateX(-180deg) translateZ(20px);
}
#cuboid div:nth-child(4) {
    transform: rotateX(-270deg) translateZ(20px);
    -webkit-transform: rotateX(-270deg) translateZ(20px);
    -moz-transform: rotateX(-270deg) translateZ(20px);
}

#cuboid form:hover,
#cuboid form.ready {
    transform: translateZ(-20px) rotateX(90deg);
    -webkit-transform: translateZ(-20px) rotateX(90deg);
    -moz-transform: translateZ(-20px) rotateX(90deg);
}
#cuboid form.loading {
    transform: translateZ(-20px) rotateX(180deg);
    -webkit-transform: translateZ(-20px) rotateX(180deg);
    -moz-transform: translateZ(-20px) rotateX(180deg);
}
#cuboid form.complete {
    transform: translateZ(-20px) rotateX(270deg);
    -webkit-transform: translateZ(-20px) rotateX(270deg);
    -moz-transform: translateZ(-20px) rotateX(270deg);
}

@media screen and (max-width: 768px) {
    #cuboid {
        width: 100%;
    }

    .cuboid-text.finish {
        font-size: 14px;
    }
}
